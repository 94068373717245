var Handlebars = require("C:\\OTRIAN\\REPOSITORIES\\Kavala.gov\\git\\CityWatch.Mvc\\node_modules\\handlebars\\runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-file d-flex added\">\r\n	<div class=\"file-image\" style=\"background-image: url('"
    + container.escapeExpression(container.lambda(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "')\"></div>\r\n\r\n	<div class=\"flex-column d-none\">\r\n		<button class=\"btn form-btn js-remove-file\" type=\"button\">\r\n			<i class=\"fa fa-trash\"></i>\r\n		</button>\r\n		<a href=\"#\" class=\"btn form-btn\">\r\n			<i class=\"fa fa-upload\"></i>\r\n		</a>\r\n	</div>\r\n</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"files") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":1,"column":0},"end":{"line":14,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useBlockParams":true});