import tpl from './templates/map-search-bar.hbs';
import { isInBounds, formatAddress } from './mapUtils';
import { cities } from './config';

const config = {
	geolocation: !!navigator.geolocation,
	geoSearchBtnClass: 'geo-search-btn',
	searchInputClass: 'address-search-input',
	searchSubmitBtn: 'search-submit',
	googleMapsKey: 'AIzaSyCDNWtPS2tR9aTYrdC5Zesaj2kvW4CpwLk'
};

const geoConfig = {
	enableHighAccuracy: true,
	maximumAge: 30000,
	timeout: 27000
};

class MapSearchBar {
	constructor({ $el, onLocationSearch, onMarkerOutOfBounds }) {
		this.$el = $el;
		this.config = config;
		this.geoConfig = geoConfig;
		this.onLocationSearch = onLocationSearch;
		this.onMarkerOutOfBounds = onMarkerOutOfBounds;
		this.geocoder = new google.maps.Geocoder();
	}

	render() {
		this.$el.html(tpl(config));
		this.$geoSearchBtn = this.$el.find(`.${this.config.geoSearchBtnClass}`);
		this.$searchInput = this.$el.find(`.${this.config.searchInputClass}`);
		this.$searchSubmitBtn = this.$el.find(`.${this.config.searchSubmitBtn}`);
		this.events();
	}

	events() {
		this.$searchSubmitBtn.on('click', this.onSearchSubmit.bind(this));
		this.$searchInput.on('focusout', this.onSearchSubmit.bind(this));
		this.$searchInput.on('keypress', e => {
			if (e.key === 'Enter' ) {
				e.preventDefault();
				this.onSearchSubmit();
			}
		});
		this.$geoSearchBtn.on('click', this.searchGeoLocation.bind(this));
	}

	onSearchSubmit() {
		const searchIpuntVal = this.$searchInput.val().trim();
		
		if (searchIpuntVal === "") {
			return;
		}

		const address = searchIpuntVal + ', Καβάλα';

		this.searchLocation({ address }).then(results => { 
			const location = results[0].geometry.location;
			const lat = location.lat();
			const lng = location.lng();
			const coords = { lat, lng }
			const addressObj = results[0].address_components;
			const address = (addressObj[1].long_name  + " " + addressObj[0].long_name + ", " + addressObj[2].short_name)
			

			if (!isInBounds(coords, cities.Kavala.bounds)) {
				return this.onMarkerOutOfBounds();
			}
			
			this.onLocationSearch(coords, address);
			this.updateSearchInputValue(address);
		});
		
	}
	updateSearchInputValue(address) {
		this.$searchInput.val(address);
	}

	searchLocation(params) { 													// Converts Address Name to GeoLocation LatLng
		return new Promise((resolve, reject) => {
			this.geocoder.geocode(params, function(results, status) {
				if (status === 'OK') {
					resolve(results);	
				} else {
					reject(status);
				}
			})
		})
	}

	searchGeoLocation() {
		navigator.geolocation.getCurrentPosition(
			position => {
				this.searchLocation({
					latlng: `${position.coords.latitude},${
						position.coords.longitude
					}`
				}).then(this.onLocationSearch);
			},
			err => console.log(err),
			this.geoConfig
		);
	}
}

export default MapSearchBar;
