import "./scss/main.scss";
import "./pagination";
import "./actions";
import "./files";
import "./accessibility";
import "./comments";

import bootbox from "bootbox";
import toastr from "toastr";
import "jquery-sticky";
import { removeAcc } from "./utilities";
import Map from "./map/Map";

window.toastr = toastr;
toastr.options.timeOut = 1500;

bootbox.addLocale("el", {
  CANCEL: "Ακύρωση",
  OK: "OK",
  CONFIRM: "ΟΚ",
});

bootbox.setLocale("el");

$.fn.extend({
  removeAcc: function() {
    return this.each(removeAcc);
  },
});

jQuery.fn.extend({
  fadeOnTime: function(timeout) {
    return this.each(function() {
      setTimeout(() => {
        $(this).fadeOut();
      }, timeout);
    });
  },
});

$("#list-map").each(function() {
  const $map = $(this);
  const map = new Map($map);
  map.render();
  $map.sticky({ topSpacing: 15 });

  $(document).on("searchResults/update", () => map.render());
});

$("#details-map").each(function() {
  const $map = $(this);
  const map = new Map($map);
  map.render();
});
$("#form-map").each(function() {
  const $map = $(this);
  const map = new Map($map);
  map.render();
});

$("h1, h2, h3, h4, h5, h6, label, p, .btn").removeAcc();

$("[data-show-ref]").on("change", function(e) {
  const animationTime = 150;
  const $target = $(e.currentTarget);
  const el = $target.data("show-ref");
  const $ref = $(el);

  if ($target.is("input[type=checkbox]")) {
    $target.is(":checked") ? $ref.fadeIn(animationTime) : $ref.hide();
    return;
  }

  if ($target.is("select")) {
    const val = $target.val();
    const $currentRef = $ref.filter(`[data-val=${val}]`);

    $ref.hide();
    $currentRef.fadeIn(animationTime);
    return;
  }
});
