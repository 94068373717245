export const cities = {
	Kavala: {
		center: {
			lat: 40.937905,
			lng: 24.412051
		},
		bounds: {
			north: 41.138613,
			east: 24.565037,
			south: 40.879997,
			west: 24.330461
		}
	}
};