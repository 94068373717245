$(document).on('click', '.pagination .page-link', e => {
    const $this = $(e.currentTarget);
    const $form = $('form');
    const currentPage = parseInt($('[name="Pager.CurrentPage"]').val());
    const totalPages = parseInt($('[name="Pager.TotalPages"]').val());
    const $pageChangedInput = $('[name="Pager.PageChanged"]');
    const $selectedPageInput = $('[name="Pager.SelectedPage"]');
    let selectedPage;

    if ($this.is('.page-prev')) {
        if (currentPage == 1) {
            return false;
        }

        selectedPage = currentPage - 1;
    } else if ($this.is('.page-next')) {
        if (currentPage == totalPages) {
            return false;
        }

        selectedPage = currentPage + 1;
    } else {
        selectedPage = $this.val();
    }

    $selectedPageInput.val(selectedPage);
    $pageChangedInput.val(true);
    $form.submit();
    $pageChangedInput.val(false);
});
