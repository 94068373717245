import { loader } from "./utilities";

// Delete comment button
$('body')
    .on('click', '.comment-delete-btn', function(e) {
        e.preventDefault();
        var $this = $(e.currentTarget);
        var commentID = $(this).data('commentid');
        var requestID = $(this).data('requestid');
        $.ajax({
            type: 'POST',
            url: '/Requests/CommentDelete',
            data: { commentID: commentID, encryptedRequestID: requestID },
            dataType: 'json',
            beforeSend: function() {
                loader.show();
            },
            success: function(response) {
                handleCommentDeletePostResponse(response);
                loader.hide();
            },
            failure: function(response) {
                alert(response.responseText);
                loader.hide();
            },
            error: function(response) {
                alert(response.responseText);
                loader.hide();
            }
        });
    })
    .on('click', '.comments-header', function(e) {
        $(this)
            .closest('.comments')
            .toggleClass('comments-collapsed')
            .find('.comments-main')
            .slideToggle();
    });

window.handleCommentPostResponse = context => {
    if (context.Success) {
        $('#Text').val('');
        $('#IsPublic').prop('checked', false);

        $('#nrtCommentsList').html(context.CommentsList);

        if (context.Message) {
            toastr.success(context.Message);
        }

        // window.scroll({
        //     top: $('#comments').offset().top,
        //     left: 0,
        //     behavior: 'smooth'
        // });
    } else {
        if (context.Message) {
            toastr.error(context.Message);
        }
    }
    return false;
};

window.handleCommentDeletePostResponse = context => {
    if (context.Success) {
        $('#nrtCommentsList').html(context.CommentsList);

        if (context.Message) {
			toastr.success(context.Message)
        }
    } else {
        if (context.Message) {
            toastr.error(context.Message)
        }
    }
    return false;
};
