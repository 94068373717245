import tpl from './templates/map-label.hbs';

const config = {
	elClass: 'map-label',
	labelContainerClass: 'label-container',
	editInputClass: 'edit-input',
	labelValueClass: 'label-input',
	editContainerClass: 'edit-container',
	editBtnClass: 'edit-btn',
	editCancelBtnClass: 'edit-cancel-btn',
	editSubmitBtnClass: 'edit-submit-btn'
};

class MapLabel {
	constructor({ $el, value, isEditable, onLabelChange }) {
		this.$el = $el;
		// this.value = value;
		this.isEditable = isEditable;
		this.onLabelChange = onLabelChange;
		this.config = { ...config, isEditable, value };
	}

	render() {
		this.$el.html(tpl(this.config));
		this.$labelContainer = this.$el.find(
			`.${this.config.labelContainerClass}`
		);
		this.$labelValue = this.$el.find(`.${this.config.labelValueClass}`);
		this.$editBtn = this.$el.find(`.${this.config.editBtnClass}`);
		this.$editContainer = this.$el.find(
			`.${this.config.editContainerClass}`
		);
		this.$editInput = this.$el.find(`.${config.editInputClass}`);
		this.$editCancelBtn = this.$el.find(
			`.${this.config.editCancelBtnClass}`
		);
		this.$editSubmitBtn = this.$el.find(
			`.${this.config.editSubmitBtnClass}`
		);
		this.events();
	}

	events() {
		this.$editInput.on('keypress', this.onLabelEditKeyPress.bind(this));
		this.$editBtn.on('click', this.onLabelEdit.bind(this));
		this.$editCancelBtn.on('click', this.onLabelEditCancel.bind(this));
		this.$editSubmitBtn.on('click', this.onLabelEditSubmit.bind(this));
	}

	changeLabel(value) {
		this.$labelValue.val(value);
	}

	onLabelEditKeyPress(e) {
		if (e.key === 'Enter') {
			e.preventDefault();
			this.onLabelEditSubmit();
		}
	}

	onLabelEditSubmit() {
		const labelValue = this.$editInput.val();

		if (labelValue.trim() === '') {
			return;
		}

		this.onLabelChange(labelValue);
		this.toggleEdit();
	}

	onLabelEditCancel() {
		this.toggleEdit();
	}

	onLabelEdit() {
		this.$editInput.val(this.$labelValue.val());
		this.toggleEdit();
	}

	toggleEdit() {
		this.$labelContainer.toggle();
		this.$editContainer.toggle();
	}
}

export default MapLabel;
