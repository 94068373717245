export const loader = {
    show() {
        $('#loader').show();
    },
    hide() {
        $('#loader').hide();
    }
};

export const removeAcc = function() {
    let text =
        this.tagName.toLowerCase() == 'input' ? this.value : this.innerHTML;

    if (
        text !== text.toUpperCase() &&
        $(this).css('text-transform') != 'uppercase'
    )
        return;

    text = text.replace(/Ά/g, 'Α');
    text = text.replace(/ά/g, 'α');
    text = text.replace(/Έ/g, 'Ε');
    text = text.replace(/έ/g, 'ε');
    text = text.replace(/Ή/g, 'Η');
    text = text.replace(/ή/g, 'η');
    text = text.replace(/Ί/g, 'Ι');
    text = text.replace(/Ϊ/g, 'Ι');
    text = text.replace(/ί/g, 'ι');
    text = text.replace(/ϊ/g, 'ι');
    text = text.replace(/ΐ/g, 'ι');
    text = text.replace(/Ό/g, 'Ο');
    text = text.replace(/ό/g, 'ο');
    text = text.replace(/Ύ/g, 'Υ');
    text = text.replace(/Ϋ/g, 'Υ');
    text = text.replace(/ύ/g, 'υ');
    text = text.replace(/ϋ/g, 'υ');
    text = text.replace(/ΰ/g, 'υ');
    text = text.replace(/Ώ/g, 'Ω');
    text = text.replace(/ώ/g, 'ω');

    this.tagName.toLowerCase() == 'input'
        ? (this.value = text)
        : (this.innerHTML = text);
};
