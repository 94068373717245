import photosTpl from './templates/photos.hbs';
import documentsTpl from './templates/documents.hbs';

const getFilesInfo = files =>
	Array.from(files).map(file => {
		file.url = URL.createObjectURL(file);
		file.extension = file.name.split('.').pop();
		return file;
	});

const fileTemplates = {
	photos: photosTpl,
	documents: documentsTpl
}

$('.form-files').on('change', '.upload-files', function(e) {
    const $container = $(e.delegateTarget);
    const input = e.target;
	const files = getFilesInfo(input.files);
	const template = fileTemplates[$container.data('template')];

    $container
        .find('.form-file.added')
        .remove()
        .end()
        .find('.add-file')
        .before(template({ files }));
});