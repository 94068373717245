import { isInBounds } from './mapUtils';
import _flow from 'lodash/flow';
import { cities } from './config';

const defaultMapConfig = {
	center: { lat: cities.Kavala.center.lat, lng: cities.Kavala.center.lng },
	zoom: 15,
	maxZoom: 18,
	mapTypeId: google.maps.MapTypeId['ROADMAP']
};

const mapCanvas = ({
	$el,
	userMapConfig,
	locations = [],
	onSearchedCoordsChange,
	onMarkerOutOfBounds,
	onLocationSearch
	
	
}) => {
	const mapConfig = { ...defaultMapConfig, ...userMapConfig };
	let map;
	let searchedMarker;
	let infowindow;
	
	
	let geocoder = new google.maps.Geocoder();
	let pinnedAddress;
	let marker;

	const render = () => {
		map = new google.maps.Map($el[0], mapConfig);
		typeof locations !== 'string' && locations.map(renderLocation);
	};


	const renderLocation = location =>
		_flow(setupMarker, draggable, searchable, bindable, centered, withInfo)(
			location
		);


	const setupMarker = location => {  //after search submit marker gets
		const { lat, lng, icon, svg } = location;
		let svgIcon;

		if (!lat || !lng) {
			return false;
		}

		if (svg) {
			svgIcon = {
				url:
					'data:image/svg+xml;charset=UTF-8,' +
					encodeURIComponent(svg),
				scaledSize: new google.maps.Size(20, 20)
			};
		}

		const position = new google.maps.LatLng(lat, lng);
		
		const marker = new google.maps.Marker({
			position,
			icon: svgIcon || icon
		});
		const bounds = new google.maps.LatLngBounds();
		marker.metadata = { ...location };

		marker.setMap(map);
		bounds.extend(marker.position);

		return marker;
	};

	const removeMarker = marker => marker.setMap(null);

	const draggable = marker => {
		const { isDraggable } = marker.metadata;

		if (isDraggable) {
			marker.setDraggable(true);

			onMarkerDrag(cities.Kavala.bounds)(
				marker,
				onSearchedCoordsChange,
				onMarkerOutOfBounds
			);
		}

		return marker;
	};

	const searchable = marker => {
		const { isSearchable } = marker.metadata;

		if (isSearchable) {
			searchedMarker && removeMarker(searchedMarker);
			searchedMarker = marker;
		}

		return marker;
	};

	const bindable = marker => {
		const { bindToEl: $el } = marker.metadata;

		$el && $el.data('marker', marker);

		return marker;
	};

	const centered = marker => {
		const { isDefault, lat, lng } = marker.metadata;

		isDefault && map.setCenter({ lat, lng });

		return marker;
	};

	const withInfo = marker => {
		const { infoText } = marker.metadata;

		infoText &&
			marker.addListener(
				'click',
				openInfoWindow.bind(null, marker, infoText)
			);

		return marker;
	};

	const openInfoWindow = (marker, text) => {
		infowindow && infowindow.close();

		infowindow = new google.maps.InfoWindow({
			content: `<h6>${text}</h6>`
		});

		infowindow.open(map, marker);
	};

	const onMarkerClick = marker =>
		openInfoWindow(marker, marker.metadata.infoText);

	const onMarkerDrag = bounds => (
		marker,
		onSuccess,
		onFail
	) => {
		let latStart;
		let lngStart;
		
		google.maps.event.addListener(marker, 'dragstart', e => {
			latStart = e.latLng.lat();
			lngStart = e.latLng.lng();
		});
	
		google.maps.event.addListener(marker, 'dragend', e => {
			const lat = e.latLng.lat();
			const lng = e.latLng.lng();
			const input = (lat + "," + lng);
			const latlngStr = input.split(',', 2);
			const latlng = {lat: parseFloat(latlngStr[0]), lng: parseFloat(latlngStr[1])};
			const isMarkerInBounds = isInBounds({ lat, lng}, bounds);
			
			
			if (!isMarkerInBounds) {
				marker.setPosition({ lat: latStart, lng: lngStart });
				return onFail(isMarkerInBounds)			
			}else{
				searchLatLng( {'location':latlng} ).then(results => {      //Converts latlng to address
					var addressComponents = results[0].address_components;
					pinnedAddress = (addressComponents[1].long_name + " " + addressComponents[0].long_name + ", " + addressComponents[2].short_name);
					onLocationSearch(latlng, pinnedAddress);
				});
			}
			return onSuccess({ lat: e.latLng.lat(), lng: e.latLng.lng() })
		});
	};

	
	const searchLatLng = LatLng => {
		return new Promise((resolve, reject) => {
			geocoder.geocode(LatLng, function(results, status) {
				if (status === 'OK') {
					resolve(results);	
				} else {
					reject(status);
				}
			})
		})
	}


	const fitMarkersOnMap = markers => {
		const bounds = new google.maps.LatLngBounds();
		markers.forEach(marker => {
			bounds.extend(marker.getPosition());
		});

		map.fitBounds(bounds);
	}

	return {
		render,
		// renderMarker,
		onMarkerClick,
		renderLocation,
		fitMarkersOnMap,
		onMarkerDrag
	};
};

export default mapCanvas;
