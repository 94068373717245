import bootbox from 'bootbox';
import { loader } from './utilities';

$('.form-actions').on('click', '.btn-save', e => {
	e.preventDefault();

	bootbox.confirm(
		'Είστε σίγουροι ότι θέλετε να αποθηκεύσετε το αίτημα;',
		result => (result && $('form').submit()) || undefined
	);
});

$('.request-form').on('change.fields', e => {
	const $this = $(e.delegateTarget);

	$this
	.off('change.fields')
	.on('click', '.form-actions .btn-cancel', e => {
		console.log('click');
		e.preventDefault();
		const location = $(e.target).attr('href');
		console.log(location);

		bootbox.confirm(
			'Είστε σίγουροι ότι θέλετε να ακυρώσετε την επεξεργασία του αιτήματος;',
			result => {
				if (result) {
					loader.show();
					window.location.href = location;
				}
			}
		);
	});
});

$('#submitAction').on('click', function(e) {
	e.preventDefault();
	const requestID = $(this).data('id');
	const selectedValue = $('#drpActions').val();
	console.log(selectedValue);
	if (!selectedValue) {
		return;
	}
	

	$.post('/Requests/EditActions', {
		encryptedRequestID: requestID,
		encryptedRequestStatusActionID: selectedValue
	})
		.done(message => bootbox.dialog({ message }))
		.fail(error => console.log(error.responseText));
});

$('#deleteRequest').on('click', function(e) {
	e.preventDefault();
	const requestID = $(this).data('id');

	$.get('/Requests/Delete', { id: requestID })
		.done(message => bootbox.dialog({ message }))
		.fail(error => console.log(error.responseText));
});

$('.remove-file').on('click', e => {
	const $button = $(e.currentTarget);
	const $file = $button.closest('.form-file');

	bootbox.confirm(
		'Είστε σίγουροι ότι θέλετε να διαγράψετε το αρχείο;',
		result => (result && handleRemoveFile($button, $file)) || undefined
	);
});

const handleRemoveFile = ($button, $file) => {
	const requestID = $button.data('id');
	const guid = $button.data('guid');

	loader.show();

	$.post('/Requests/RemoveFile', {
		encryptedRequestID: requestID,
		fileGUID: guid
	})
		.done(response => {
			if (response === 'True') {
				$file.remove();
			}
		})
		.fail(error => console.log(error.responseText))
		.always(() => loader.hide());
};

// sign out
$('body').on('click', '#btnSignOut', function(e) {
	e.preventDefault();
	//var $this = $(e.currentTarget);
	//var requestID = $(this).data('id');
	$.ajax({
		type: 'POST',
		url: '/User/SignOut',
		data: {},
		dataType: 'html',
		success: function(response) {
			window.location.reload();
		},
		failure: function(response) {
			alert(response.responseText);
		},
		error: function(response) {
			alert(response.responseText);
		}
	});
});

// Subscribe button
$('body').on('click', '.btn-add-bookmark', function(e) {
	e.preventDefault();
	var $this = $(e.currentTarget);
	var commentID = $(this).data('id');
	$.ajax({
		type: 'POST',
		url: '/Requests/Subscribe',
		data: { id: commentID },
		dataType: 'html',
		success: function(response) {
			$this.replaceWith(response);
		},
		failure: function(response) {
			alert(response.responseText);
		},
		error: function(response) {
			alert(response.responseText);
		}
	});
});

// Like/Vote button
$('body').on('click', '.request-upvote-btn', function(e) {
	e.preventDefault();
	var $this = $(e.currentTarget);
	var requestID = $(this).data('id');
	$.ajax({
		type: 'POST',
		url: '/Requests/Like',
		data: { id: requestID },
		dataType: 'html',
		success: function(response) {
			$("#LikeVotes").html(response);
		},
		failure: function(response) {
			alert(response.responseText);
		},
		error: function(response) {
			alert(response.responseText);
		}
	});
});

$('.request-form')
	.on('submit', loader.show)
	.on('invalid-form', loader.hide);


$('.submitee').on('click', e => {
	e.preventDefault();
	const $this = $(e.target);
	const data = $this.data();
	const template = `
		<table>
		${data.name && `<tr><td class="pr-15"><b>Όνομα:</b></td> <td>${data.name}</td></tr>`}
		${data.phone && `<tr><td class="pr-15"><b>Τηλέφωνο:</b></td> <td>${data.phone}</td></tr>`}
		${data.email && `<tr><td class="pr-15"><b>E-mail</b></td> <td>${data.email}</td></tr>`}
		</table>
	`

	bootbox.dialog({
		message: `
			<h5 class='bootbox-title'>Στοιχεία επικοινωνίας πολίτη.</h5>
			${template}
		`,
		buttons: {
			ok: {}
		},
		closeButton: true
	});
})