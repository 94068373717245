export const formatAddress = (address, addressFormat = '{road} {number}') => {
	const road = 1;
	const roadNumber = 0;
	const city = 2;

	return addressFormat
		.replace('{road}', address[road].long_name)
		.replace('{number}', address[roadNumber].long_name)
		.replace('{city}', address[city].long_name)
		.replace('{postal_code}', address[address.length - 1].long_name);
};

export const coordsToStr = ({ lat, lng }) => `${lat}, ${lng}`;

export const setDraggable = (marker, callback) => {
	marker.setDraggable(true);
	marker._draggableListener = google.maps.event.addListener(
		marker,
		'dragend',
		e => callback({ lat: e.latLng.lat(), lng: e.latLng.lng() })
	);

	return marker;
};

export const unsetDraggable = marker => {
	marker.setDraggable(false);
	google.maps.event.removeListener(marker._draggableListener);
};

export const isInBounds = (coords, bounds) => {
	const { lat, lng } = coords;
	const { north, east, south, west } = bounds;

	return lat < north && lat > south && lng > west && lng < east;
} 

