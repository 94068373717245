var Handlebars = require("C:\\OTRIAN\\REPOSITORIES\\Kavala.gov\\git\\CityWatch.Mvc\\node_modules\\handlebars\\runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"input-group-prepend\">\r\n		<button type=\"button\" class=\"input-group-text "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"geoSearchBtnClass") || (depth0 != null ? lookupProperty(depth0,"geoSearchBtnClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"geoSearchBtnClass","hash":{},"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":69}}}) : helper)))
    + "\">\r\n			<i class=\"fa fa-location-arrow\"></i>\r\n		</button>\r\n	</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"geolocation") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":8,"column":8}}})) != null ? stack1 : "")
    + "	<input type=\"text\" class=\"form-control "
    + alias4(((helper = (helper = lookupProperty(helpers,"searchInputClass") || (depth0 != null ? lookupProperty(depth0,"searchInputClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchInputClass","hash":{},"data":data,"loc":{"start":{"line":9,"column":40},"end":{"line":9,"column":60}}}) : helper)))
    + "\" placeholder=\"Αναζήτηση...\">\r\n	<div class=\"input-group-append\">\r\n		<button type=\"button\" class=\"input-group-text "
    + alias4(((helper = (helper = lookupProperty(helpers,"searchSubmitBtn") || (depth0 != null ? lookupProperty(depth0,"searchSubmitBtn") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchSubmitBtn","hash":{},"data":data,"loc":{"start":{"line":11,"column":48},"end":{"line":11,"column":67}}}) : helper)))
    + "\">\r\n			<i class=\"fa fa-search\"></i>\r\n		</button>\r\n	</div>\r\n</div>";
},"useData":true});