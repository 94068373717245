var Handlebars = require("C:\\OTRIAN\\REPOSITORIES\\Kavala.gov\\git\\CityWatch.Mvc\\node_modules\\handlebars\\runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "placeholder=\"Διεύθυνση...\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"input-group-append ml-auto\">\r\n		<button type=\"button\" class=\"input-group-text text-info px-10 "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"editBtnClass") || (depth0 != null ? lookupProperty(depth0,"editBtnClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"editBtnClass","hash":{},"data":data,"loc":{"start":{"line":7,"column":64},"end":{"line":7,"column":82}}}) : helper)))
    + "\" style=\"border: 0;\">\r\n			<i class=\"fa fa-pencil\"></i>\r\n		</button>\r\n	</div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"editContainerClass") || (depth0 != null ? lookupProperty(depth0,"editContainerClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"editContainerClass","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":36}}}) : helper)))
    + "\" style=\"display:none;\">\r\n	<div class=\"input-group\">\r\n		<input type=\"text\" class=\"form-control "
    + alias4(((helper = (helper = lookupProperty(helpers,"editInputClass") || (depth0 != null ? lookupProperty(depth0,"editInputClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"editInputClass","hash":{},"data":data,"loc":{"start":{"line":17,"column":41},"end":{"line":17,"column":61}}}) : helper)))
    + "\" placeholder=\"Διεύθυνση...\">\r\n		<div class=\"input-group-append\">\r\n			<button type=\"button\" class=\"input-group-text text-danger "
    + alias4(((helper = (helper = lookupProperty(helpers,"editCancelBtnClass") || (depth0 != null ? lookupProperty(depth0,"editCancelBtnClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"editCancelBtnClass","hash":{},"data":data,"loc":{"start":{"line":19,"column":61},"end":{"line":19,"column":85}}}) : helper)))
    + "\">\r\n				<i class=\"fa fa-times\"></i>\r\n			</button>\r\n			<button type=\"button\" class=\"input-group-text text-success "
    + alias4(((helper = (helper = lookupProperty(helpers,"editSubmitBtnClass") || (depth0 != null ? lookupProperty(depth0,"editSubmitBtnClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"editSubmitBtnClass","hash":{},"data":data,"loc":{"start":{"line":22,"column":62},"end":{"line":22,"column":86}}}) : helper)))
    + "\">\r\n				<i class=\"fa fa-check\"></i>\r\n			</button>\r\n		</div>\r\n	</div>\r\n</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group "
    + alias4(((helper = (helper = lookupProperty(helpers,"labelContainerClass") || (depth0 != null ? lookupProperty(depth0,"labelContainerClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelContainerClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":24},"end":{"line":1,"column":49}}}) : helper)))
    + "\">\r\n	<input class=\"form-control "
    + alias4(((helper = (helper = lookupProperty(helpers,"labelValueClass") || (depth0 != null ? lookupProperty(depth0,"labelValueClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelValueClass","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":49}}}) : helper)))
    + "\" disabled readonly value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":2,"column":76},"end":{"line":2,"column":87}}}) : helper)))
    + "\" \r\n	"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":1},"end":{"line":3,"column":53}}})) != null ? stack1 : "")
    + ">\r\n	\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditable") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":1},"end":{"line":11,"column":8}}})) != null ? stack1 : "")
    + "</div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditable") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":0},"end":{"line":28,"column":7}}})) != null ? stack1 : "")
    + "\r\n<style>\r\n	."
    + alias4(((helper = (helper = lookupProperty(helpers,"labelValueClass") || (depth0 != null ? lookupProperty(depth0,"labelValueClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelValueClass","hash":{},"data":data,"loc":{"start":{"line":31,"column":2},"end":{"line":31,"column":23}}}) : helper)))
    + " {\r\n		padding-left: 0 !important;\r\n		border-color: transparent!important;\r\n		background-color: transparent!important;\r\n	}\r\n</style>";
},"useData":true});