$(function() {
  var body = $("body");
  var accessibility = $("#accessibility");
  var accessibilityBtn = $("#accessibilityBtn");
  var increaseFontSize = $("#increaseFontSize");
  var descreseFontSize = $("#descreseFontSize");
  var highContrast = $("#highContrast");
  var reverseColor = $("#reverseColor");
  var lightBg = $("#lightBg");
  var indicateLinks = $("#indicateLinks");
  var changeFont = $("#changeFont");
  var resetThemes = $("#resetThemes");
  var panel = $(".accessibility-wrapper");
  var curSize;

  // TOGGLE ACCESSIBILITY PANEL

  accessibilityBtn.on("click", function(e) {
    e.preventDefault();
    panel.toggleClass("open");
  });

  // RESET THEMES

  resetThemes.on("click", function() {
    body.attr("class", "");
    $("html").css("font-size", "16px");
    if ($("a").hasClass("underlined-link")) {
      $("a").removeClass("underlined-link");
    }
  });

  // TOGGLE LINK HIGHTLIGHT

  indicateLinks.on("click", function() {
    $("a").toggleClass("underlined-link");
  });

  //TOGGLE REVERSE COLORS

  reverseColor.on("click", function() {
    if (body.hasClass("reverse-theme")) {
      body.removeClass("reverse-theme");
    } else {
      body.attr("class", "");
      body.addClass("reverse-theme");
      $("#map-container").css("background-color", "unset");
    }
  });

  // ACTIVATE SIMPLE FONTS

  changeFont.on("click", function() {
    if (body.hasClass("simple-font")) {
      body.removeClass("simple-font");
    } else {
      body.attr("class", "");
      body.addClass("simple-font");
    }
  });

  //LIGHT BACKGROUND
  lightBg.on("click", function() {
    if (body.hasClass("light-background")) {
      body.removeClass("light-background");
    } else {
      body.attr("class", "");
      body.addClass("light-background");
    }
  });

  // HIGH CONSTRAST TOGGLE

  highContrast.on("click", function() {
    if (body.hasClass("dark-theme")) {
      body.removeClass("dark-theme");
    } else {
      body.attr("class", "");
      body
        .addClass("dark-theme")
        .children()
        .not("#map-container");
    }
  });

  // INCREASE FONTS SIZE

  increaseFontSize.on("click", function() {
    curSize = parseInt($("html").css("font-size")) + 2;
    if (curSize <= 28) $("html").css("font-size", curSize);
  });

  // RESET FONTS SIZE

  //   $("#resettext").on("click", function () {
  //     if (curSize != 16) $("body").css("font-size", 16);
  //   });

  // DECREASE FONTS SIZE

  descreseFontSize.on("click", function() {
    curSize = parseInt($("html").css("font-size")) - 2;
    if (curSize >= 14) $("html").css("font-size", curSize);
  });
});
