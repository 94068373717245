import _isEmpty from 'lodash/isEmpty';

const config = {
	listItemClass: 'list-item',
	activeClass: 'selected',
	listItemChildClass: 'request-address'
};
class MapList {
	constructor({ $el, onListItemSelect, renderLocation, fitMarkersOnMap }) {
		this.$el = $el;
		this.config = config;
		this.renderLocation = renderLocation;
		this.onListItemSelect = onListItemSelect;
		this.fitMarkersOnMap = fitMarkersOnMap
		this.$listItems;
	}

	render() {
		let markers = [];
		this.$listItems = this.$el.find(`.${this.config.listItemClass}`);

		this.$listItems.each((index, item) => {
			const $this = $(item);
			const location = $this.data('location');


			if (!location || _isEmpty(location)) {
				return;
			}
			
			const marker = this.renderLocation({ ...location, bindToEl: $this });

			marker && markers.push(marker);

			$this.find(`.${config.listItemChildClass}`).on('click', e => {
				e.preventDefault();
				this.onListItemClick($this);
			});
			marker.addListener('click', this.onListItemClick.bind(this, $this));
		});
		markers.length && this.fitMarkersOnMap(markers);
	}

	onListItemClick($el) {
		const marker = $el.data('marker');
		this.onListItemSelect(marker);
		this.$listItems.removeClass(config.activeClass);
		$el.addClass(config.activeClass);
		this.animateToListItem($el);
	}

	animateToListItem($el) {
		$('body, html').animate({ scrollTop: $el.offset().top }, 350);
	}
}

export default MapList;
