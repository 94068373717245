import MapSearchBar from './MapSearchBar';
import MapLabel from './MapLabel';
import mapTpl from './templates/map.hbs';
import MapList from './MapList';
import mapCanvas from './MapCanvas';
import { cities } from './config';

const config = {
	searchBarClass: 'map-search-bar',
	canvasClass: 'map-canvas',
	labelClass: 'map-label',
	listClass: 'map-list',
	latInputName: 'RequestGeoLatitude',
	lngInputName: 'RequestGeoLongitude',
	addressInputName: 'RequestGeoAddress',
	errorLabelClass: 'error-label',
	invalidLocationMsg: 'Η τοποθεσία είναι εκτός ορίων του Δήμου. Παρακαλώ δοκιμάστε μια νέα αναζήτηση.'
};

class Map {
	constructor($el) {
		this.$el = $el;
		this.props = { ...this.$el.data(), ...config };
		this.childComponents = {};
	}

	setupChildComponents() {
		return {
			
			mapCanvas: mapCanvas({
				$el: this.$el.find(`.${config.canvasClass}`),
				mapConfig: this.props.mapConfig,
				locations: this.props.locations,
				onSearchedCoordsChange: this.onSearchedCoordsChange.bind(this),
				onMarkerOutOfBounds: this.onMarkerOutOfBounds.bind(this),
				onLocationSearch: this.onLocationSearch.bind(this),
			}),
			mapSearchBar:
				this.props.hasSearch &&
				new MapSearchBar({
					$el: this.$el.find(`.${config.searchBarClass}`),
					onLocationSearch: this.onLocationSearch.bind(this),
					onMarkerOutOfBounds: this.onMarkerOutOfBounds.bind(this),
					

				}),

			mapLabel:
				this.props.hasLabel &&
				new MapLabel({
					$el: this.$el.find(`.${config.labelClass}`),
					value: this.props.defaultLabel,
					isEditable: this.props.isLabelEditable,
					onLabelChange: this.onLabelChange.bind(this)
				}),
			mapList:
				this.props.hasList && new MapList({
					$el: $(`.${config.listClass}`),
					renderLocation: this.renderLocation.bind(this),
					onListItemSelect: this.onListItemSelect.bind(this),
					fitMarkersOnMap: this.fitMarkersOnMap.bind(this)
				}),
			mapMarkerInput:
				this.props.hasMarkerInput && new MapList({
					$el: $(`.${config.markerInputClass}`),

				})
		};
	}

	onListItemSelect(marker) {
		this.childComponents.mapCanvas.onMarkerClick(marker);
		this.childComponents.mapLabel.changeLabel(marker.metadata && marker.metadata.address);
	}
	
	scrollToMap(){
		const documentScrollTop = $(document).scrollTop();
		const mapOffsetTop = this.$el.offset().top;

		if (documentScrollTop > mapOffsetTop) {
			$(document).scrollTop(mapOffsetTop);
		}
	}

	renderLocation(location) {
		//return this.childComponents.mapCanvas.renderLocation(cities.Kavala.center);
		return this.childComponents.mapCanvas.renderLocation(location);
	}

	onLabelChange(address) {
		this.childComponents.mapLabel.changeLabel(address);
		this.updateAddress(address);
	}

	onLocationSearch(coords, address) {
		console.log('eeee', coords, address)

		var location = { ...coords, isDraggable: true, isDefault: true, isSearchable: true }
		this.childComponents.mapLabel.changeLabel(address);
		this.childComponents.mapCanvas.renderLocation(location)
		this.updateAddress(address);
		this.updateCoords(coords);
	}

	onSearchedCoordsChange(coords) {
		this.updateCoords(coords);
	}

	onMarkerOutOfBounds() {
		this.$errorLabel.text(config.invalidLocationMsg).show();
	}

	fitMarkersOnMap(markers) {
		this.childComponents.mapCanvas.fitMarkersOnMap(markers);
	}

	render() {
		this.$el.html(mapTpl(this.props));
		this.$latInput = $(`[name=${config.latInputName}]`)
		this.$lngInput = $(`[name=${config.lngInputName}]`)
		this.$addressInput = $(`[name=${config.addressInputName}]`)
		this.$errorLabel = $(`.${config.errorLabelClass}`);
		this.childComponents = this.setupChildComponents();
		Object.values(this.childComponents).forEach(component => component && component.render());
		
	}
	renderWithMarker() {
		this.render();
		
	}
	
	
	
	updateCoords({ lat, lng}) {
		this.$latInput.val(lat);
		this.$lngInput.val(lng);
		this.$errorLabel.hide();
	}

	updateAddress(address) {
		this.$addressInput.val(address);
		this.childComponents.mapSearchBar.updateSearchInputValue(address);
	}
}

export default Map;
